import { GET_PROJECTS, GET_PROJECT_DETAILS } from './types'
import { getProjects, getProjectDetails } from '../../utils/api'

export const getProjectsAction = (page, category_id) => (dispatch) => {
    return getProjects(page, category_id).then((res) => {
        var projects = res.data.data
        dispatch({
            type: GET_PROJECTS,
            payload: projects
        })
        console.log("RESPONSE RECEIVED: ", res);
        return res
    }).catch((err) => {

        console.log("AXIOS ERROR: ", err);
        return Promise.reject(err)
    })

}
export const getProjectDetailsAction = (id) => (dispatch) => {
    return getProjectDetails(id).then((res) => {
        var project = res.data.data
        dispatch({
            type: GET_PROJECT_DETAILS,
            payload: project
        })
        console.log("RESPONSE RECEIVED: ", res);
        return res
    }).catch((err) => {
        console.log("AXIOS ERROR: ", err);
        return Promise.reject(err)
    })

}