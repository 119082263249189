import React, { Component } from 'react';
import { Modal as AntModal } from 'antd'
import { ProductGallery } from '../components'
import { languages } from '../../utils/languages'
import { connect } from 'react-redux'
class SliderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_images_modal_visible: false
        }
    }
    render() {
        const { sliders, locale } = this.props
        return (
            <React.Fragment>
                <div className='d-flex justify-content-end py-3 px-3'>
                    <span className='font-medium link-theme-color weight-600' onClick={() => { this.setState({ all_images_modal_visible: true }) }}>
                        {languages[locale]['more']}
                    </span>
                </div>
                <AntModal
                    title={false}
                    footer={false}
                    closable={false}
                    centered={true}
                    visible={this.state.all_images_modal_visible}
                    onCancel={() => { this.setState({ all_images_modal_visible: false }) }}
                >
                    <ProductGallery sliders={sliders} />
                </AntModal>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    locale: state.layout.locale,

})


export default connect(mapStateToProps, {})(SliderModal);
