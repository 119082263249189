import { GET_PRINCIPLES } from './types'
import { getPrinciples } from '../../utils/api'
export const getPrinciplesAction = () => (dispatch) => {
    return getPrinciples().then((res) => {
        var principles = res.data.data.map((principle) => { return principle.name })
        dispatch({
            type: GET_PRINCIPLES,
            payload: principles
        })
        console.log("RESPONSE RECEIVED: ", res);
        return res
    }).catch((err) => {

        console.log("AXIOS ERROR: ", err);
        return Promise.reject(err)
    })

}