import { GET_MAIN_IMAGES } from '../actions/types'

const initial_state = {
    images: [],
}
export default function (state = initial_state, action) {
    switch (action.type) {
        case GET_MAIN_IMAGES:
            return {
                ...state,
                images: action.payload
            }


        default:
            return state
    }
}