import React, { FC } from "react";
import { SVGIcon } from ".";
import { connect } from 'react-redux'
import { languages } from '../../utils/languages'
import { Link } from 'react-router-dom'

const Footer = (props) => {
  const { locale, settings } = props
  return (
    <div className='footer-filter'>
      <footer className="footer" id='contact-us'>
        <div className="container-fluid flex-lg-row-reverse flex-column align-items-center px-md-5">
          <img
            src="/images/logo.png"
            alt="Aldoomain Logo"
            className="footer-logo mt-lg-0 mt-4"
          />
          <div className="footer-content">
            <div className="footer-social">
              <a target='_blank' href={settings.whatsapp}>
                <SVGIcon id="#simple-whatsapp" title="WhatsApp" />
              </a>
              <a target='_blank' href={settings.instagram}>
                <SVGIcon id="#instagram" title="Instagram" />
              </a>
              <a target='_blank' href={settings.twitter}>
                <SVGIcon id="#twitter" title="Twitter" />
              </a>
              <a target='_blank' href={settings.facebook}>
                <SVGIcon id="#facebook" title="Facebook" />
              </a>
            </div>
            <div className="d-flex flex-lg-row flex-column align-items-center mt-5">
              {/* <img src="/images/map.png" alt="Map" className="footer-map" /> */}
              <iframe
                className='footer-map'
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d64710.85764280527!2d46.690248675034304!3d24.72516625731286!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x57ac8512eb218540!2z2KfZhNiv2YjZhdin2YYg2YTZhNij2KvYp9irICYg2KfZhNiv2YrZg9mI2LE!5e0!3m2!1sen!2seg!4v1602521526553!5m2!1sen!2seg"
                frameBorder="0"
                allowFullScreen
                aria-hidden="false"
                tabIndex={0}></iframe>
              <div className="footer-details mx-4 mt-lg-0 mt-3">
                <h2 className='font-Xlarge text-white'>{languages[locale]['contact_us']}</h2>
                <p className="mt-4 font-medium">
                  <SVGIcon
                    id="#location"
                    title="Location"
                    className="icon location"
                  />
                  &nbsp; {settings.address}
                </p>
                <p className='font-medium'>
                  <SVGIcon id="#phone" title="Phone" />
                  &nbsp; {settings.phone}
                </p>
                <p className='font-medium'>
                  <SVGIcon id="#email" title="Email" />
                  &nbsp; {settings.email}
                </p>
                <ul className="nav footer-nav mt-4">
                  <li className="nav-item">
                    <Link to={`/${locale}/`} className="nav-link font-medium active" href="#test">
                      {languages[locale]['home']}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={`/${locale}/works`} className="nav-link font-medium" href="#test">
                      {languages[locale]['our_work']}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={`/${locale}/about-us`} className="nav-link font-medium" href="#test">
                      {languages[locale]['about']}
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link font-medium"
                      href="#test"
                    >
                      {languages[locale]['contact_us']}
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid flex-lg-row flex-column align-items-center mt-3 py-2 font-medium">
          <p>{languages[locale]['copyRights']}</p>
          <p><a className='link-white' href={'https://wide-techno.com/'} target='_blank'>{languages[locale]['designedBy']}</a></p>
        </div>
      </footer>
    </div>
  );
};


const mapStateToProps = (state) => ({
  locale: state.layout.locale,
  settings: state.settings.settings
})


export default connect(mapStateToProps, {})(Footer);
