import { GET_SLIDERS } from './types'
import { getSliders } from '../../utils/api'
export const getSlidersAction = () => (dispatch) => {
    return getSliders().then((res) => {
        var sliders = res.data.data
        dispatch({
            type: GET_SLIDERS,
            payload: sliders
        })
        console.log("RESPONSE RECEIVED: ", res);
        return res
    }).catch((err) => {

        console.log("AXIOS ERROR: ", err);
        return Promise.reject(err)
    })

}