import { WINDOW_RESIZE, CHANGE_LANG } from '../actions/types'
import { checkStorage, getStorage } from '../../helperComponents/locale-storage'
const initial_state = {
    window_width: window.innerWidth,
    locale: 'ar'
}

export default function (state = initial_state, action) {
    switch (action.type) {
        case WINDOW_RESIZE:
            return {
                ...state,
                window_width: action.payload
            }
        case CHANGE_LANG:
            return {
                ...state,
                locale: action.payload
            }
        default:
            return state
    }
}