import React, { FC } from "react";
import Carousel from "react-bootstrap/Carousel";
import RellaxWrapper from 'react-rellax-wrapper'
const MainCarousel = (props) => {
  const { silders } = props
  return (
    <Carousel pause="hover" controls={false} className="carousel--main">

      {
        silders.length == 0 ?
          <Carousel.Item>
            <Carousel.Caption>
              {/* <h3 className='text-white'><strong>متخصصون <br />بالطراز </strong>الشرقي والمغربي</h3>
              <p>منذ عام ١٩٨٨ .. تصميماً وإشرافاً وتنفيذاً</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          :
          silders.map((slide) => (
            <Carousel.Item
              style={{ backgroundImage: `url(${slide.image}` }}
            >
              {/* <div className='cover-photo-wrapper'>
                <RellaxWrapper speed={-3} style={{ height: '100%', width: '100%' }}>
                  <img className='cover-photo rellax' src={slide.image} alt={'bg'} />
                </RellaxWrapper>
              </div> */}
              <Carousel.Caption>
                <RellaxWrapper speed={-1}>
                  <div style={{ color: 'white !important' }}
                    dangerouslySetInnerHTML={{ __html: slide.title }}
                  />
                </RellaxWrapper>
              </Carousel.Caption>
            </Carousel.Item>
          ))
      }



    </Carousel>
  );
};

export default MainCarousel;
