import { GET_MAIN_IMAGES } from './types'
import { getImages } from '../../utils/api'
export const getImagesAction = (page, category_id) => (dispatch) => {
    return getImages(page, category_id).then((res) => {

        // var images = res.data.data.map((project) => (project.sliders))
        // images = [].concat.apply([], images)
        // images = images.map((image) => image.image)
        var images = res.data.data
        dispatch({
            type: GET_MAIN_IMAGES,
            payload: images
        })
        console.log("RESPONSE RECEIVED: ", res);
        return res
    }).catch((err) => {

        console.log("AXIOS ERROR: ", err);
        return Promise.reject(err)
    })

}