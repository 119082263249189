import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel, WorkCard, ValuesSection, Gallery } from "../components";
import { connect } from 'react-redux'
import { languages } from '../../utils/languages'
import { getProjectsAction } from '../../redux/actions/projectsAction'
import { getPrinciplesAction } from '../../redux/actions/principlesActions'
import { getSlidersAction } from '../../redux/actions/sliderActions'
import { WorksCarousel } from '../components'

import { LoadingOutlined } from '@ant-design/icons'
var images = [
  "/images/gallery1.jpg",
  "/images/gallery2.jpg",
  "/images/gallery3.jpg",
  "/images/gallery4.jpg",
  "/images/gallery5.jpg",
  "/images/gallery6.jpg",
  "/images/gallery7.jpg",
  "/images/gallery8.jpg",

];

const Homepage = (props) => {
  const [is_loading_works, set_loading_work] = useState(false)
  const [is_loading_Principles, set_loading_Principles] = useState(false)
  const [is_loading_sliders, set_loading_sliders] = useState(false)
  useEffect(() => {
    const { getProjects, getPrinciples, getSliders } = props

    set_loading_work(true)
    getProjects().then((res) => {
      set_loading_work(false)
    }).catch((err) => {
      set_loading_work(false)
    })

    set_loading_Principles(true)
    getPrinciples().then((res) => {
      set_loading_Principles(false)
    }).catch((err) => {
      set_loading_Principles(false)
    })

    set_loading_sliders(true)
    getSliders().then((res) => {
      set_loading_sliders(false)
    }).catch((err) => {
      set_loading_sliders(false)
    })

  }, []);
  const { locale, projects, principles, silders, settings } = props
  return (
    <div className="homepage">
      <Carousel silders={silders} />
      <section className="about section container p-lg-0">
        <p className='description font-medium weight-500' data-aos="fade-up" data-aos-offset={100} data-aos-once={true} dangerouslySetInnerHTML={{ __html: settings.about_us_description }}>
        </p>
      </section>
      <section className="section works container" id='works'>
        <header className="d-flex justify-content-between pb-4">
          <h3 className="section-title font-large">{languages[locale]['our_work']}</h3>
          <Link to={`/${locale}/works`} className="section-link font-medium">
            {languages[locale]['more']}
          </Link>
        </header>
        <div className='works-wrapper'>
          {
            is_loading_works ?
              <div className='d-flex justify-content-center py-5'>
                <span className='font-large text-theme-color'>
                  <LoadingOutlined />
                </span>
              </div>
              :
              <WorksCarousel projects={projects} />
          }

        </div>
      </section>
      {
        is_loading_Principles ?
          <div className='py-4 d-flex justify-content-center'>
            <span className='font-large text-center'>
              <LoadingOutlined />
            </span>
          </div>
          :
          <ValuesSection principles={principles} />
      }
      <section className="section">
        <Gallery />
      </section>
    </div>
  );
};


const mapStateToProps = (state) => ({
  locale: state.layout.locale,
  projects: state.projects.projects,
  principles: state.principles.principles,
  silders: state.sliders.sliders,
  settings: state.settings.settings
})
export default connect(mapStateToProps, { getProjects: getProjectsAction, getPrinciples: getPrinciplesAction, getSliders: getSlidersAction })(Homepage);