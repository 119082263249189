import { combineReducers } from 'redux'
import layoutReducer from './layoutReducer'
import categoriesReducer from './categoriesReducer'
import principlesReducer from './principlesReducer'
import sliderReducer from './sliderReducer'
import projectsReducer from './projectsReducer'
import settingsReducer from './settingsReducer'
import mainImagesReducer from './mainImagesReducer'
export default combineReducers({
    layout: layoutReducer,
    categories: categoriesReducer,
    principles: principlesReducer,
    sliders: sliderReducer,
    projects: projectsReducer,
    settings: settingsReducer,
    main_images: mainImagesReducer,
})