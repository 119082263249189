import React, { Component } from 'react';
import { ImagesCard, PaginationList } from '../components'
import { languages } from '../../utils/languages'
import { connect } from 'react-redux'
import { getCategoriesAction } from '../../redux/actions/categoriesActions'
import { getImagesAction } from '../../redux/actions/mainImagesActions'
import { LoadingOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
class ImagesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: undefined,
            last_page: 1,
            current_page: 1,

            is_loading_categories: false,
            is_loading_images: false,
        }
    }
    componentDidMount() {
        this.getCategories()
    }
    getCategories = () => {
        const { getCategories, match } = this.props
        const { category_id } = match.params
        this.setState({ is_loading_categories: true, activeIndex: category_id ? parseInt(category_id) : undefined })
        getCategories().then((res) => {
            this.setState({ is_loading_categories: false })
            this.getImages(1, category_id)
            console.log('Response', res)
        }).catch((err) => {
            this.setState({ is_loading_categories: false })
            console.log('Error', err)
        })
    }
    getImages = (page, id) => {
        const { getImages } = this.props
        this.setState({ current_page: page, is_loading_images: true })
        getImages(page, id).then((res) => {
            this.setState({
                current_page: res.data.meta.current_page,
                last_page: res.data.meta.last_page,

                is_loading_images: false
            }, () => {
                window.scrollTo(0, 0)
            })
            console.log('Response', res)
        }).catch((err) => {
            this.setState({ is_loading_images: false })
            console.log('Error', err)
        })
    }
    handleChangeIndex = (index) => {
        this.setState({ activeIndex: index }, () => {
            this.getImages(1, index)
        })
    }
    pageChange = (page) => {
        this.setState({ current_page: page }, () => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 100);

            this.getImages(page, this.state.activeIndex)
        })
    }
    render() {
        const { locale, categories, images } = this.props
        return (
            <React.Fragment>
                <div className='pb-md-5 pb-4' style={{ paddingTop: '9rem' }}>
                    <div className='works-page-wrapper'>
                        <div className='row mx-0'>
                            <div className='col-md-9 col-12 mx-auto'>
                                <div className='seaction-header mb-3'>
                                    <h3 className='font-Xlarge weight-600 text-theme-color-900'>
                                        {languages[locale]['images']}
                                    </h3>
                                </div>
                                {
                                    this.state.is_loading_categories ?
                                        <div className='py-3 d-flex justify-content-center w-100'>
                                            <span className='font-large text-theme-color'>
                                                <LoadingOutlined />
                                            </span>
                                        </div>
                                        :
                                        <header className="d-flex justify-content-center mb-4 flex-wrap" >
                                            <button

                                                className={`mx-3 my-2 font-Lsmall weight-500 btn btn-lg btn-${
                                                    this.state.activeIndex === undefined ? "primary" : "light"
                                                    }`}
                                                data-index="0"
                                                onClick={() => { this.handleChangeIndex(undefined) }}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                {languages[locale]['all']}
                                            </button>

                                            {
                                                categories.map((category, index) => (
                                                    <button key={category.id}

                                                        className={`mx-3 my-2 font-Lsmall weight-500 btn btn-lg btn-${
                                                            this.state.activeIndex === category.id ? "primary" : "light"
                                                            }`}
                                                        data-index={category.id}
                                                        onClick={() => { this.handleChangeIndex(category.id) }}
                                                        style={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        {category.name}
                                                    </button>
                                                ))
                                            }

                                        </header>
                                }
                                <div className='works-wrapper py-3'>
                                    <div className='row mx-0'>
                                        {
                                            this.state.is_loading_images || this.state.is_loading_categories ?
                                                <div className='col-12 py-5 d-flex justify-content-center w-100'>
                                                    <span className='font-large text-theme-color'>
                                                        <LoadingOutlined />
                                                    </span>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    {
                                                        images.length === 0 ?
                                                            <div className='col-12 py-5 d-flex justify-content-center w-100'>
                                                                <span className='font-medium text-theme-color'>
                                                                    No Images Found
                                                                </span>
                                                            </div>
                                                            :
                                                            images.map((image) => (
                                                                <div className="col-lg-4 col-sm-6 col-12 p-2 px-sm-2 px-4">
                                                                    <ImagesCard image={image} />
                                                                </div>
                                                            ))
                                                    }
                                                </React.Fragment>
                                        }


                                    </div>
                                </div>

                                <div className='pagination-list-wrapper d-flex justify-content-end mt-4'>
                                    <div className='white-card shadow-card'>
                                        <PaginationList total={this.state.last_page} current_page={this.state.current_page} onChange={this.pageChange} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => ({
    locale: state.layout.locale,
    categories: state.categories.categories,
    images: state.main_images.images
})

const Images = connect(mapStateToProps, { getCategories: getCategoriesAction, getImages: getImagesAction })(ImagesPage);

export default withRouter(props => <Images {...props} />)
