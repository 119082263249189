import { GET_PROJECTS, GET_PROJECT_DETAILS } from '../actions/types'

const initial_state = {
    projects: [],
    project: {},
}
export default function (state = initial_state, action) {
    switch (action.type) {
        case GET_PROJECTS:
            return {
                ...state,
                projects: action.payload
            }
        case GET_PROJECT_DETAILS:
            return {
                ...state,
                project: action.payload
            }

        default:
            return state
    }
}