import React, { FC } from "react";
import { connect } from 'react-redux'
import { languages } from '../../utils/languages'

const ValuesSection = (props) => {
  const { locale, principles } = props
  return (
    <section className="section values container " id='values'>
      <h3 className="section-title pb-4">{languages[locale]['our_value']}</h3>
      <div className="d-flex justify-content-center flex-wrap pt-3">
        {
          principles.length == 0 ?
            <div className=' d-flex justify-content-center' >
              <span className='font-medium weight-600 text-theme-color'>
                No Values Data Are Found
              </span>
            </div>
            :
            principles.map((principle, index) => (
              <p key={index} className="display-4 font-Xlarge mx-md-4 mx-3 my-4" data-aos="fade-up" data-aos-offset={100} data-aos-delay={`${300 + 150 * index}`}>{principle}</p>
            ))
        }

        {/* <p className="display-4 font-Xlarge mx-md-4 mx-3 my-4" >{languages[locale]['quality']}</p>
        <p className="display-4 font-Xlarge mx-md-4 mx-3 my-4" >{languages[locale]['honesty']}</p>
        <p className="display-4 font-Xlarge mx-md-4 mx-3 my-4" >{languages[locale]['customers_satisfaction']}</p>
        <p className="display-4 font-Xlarge mx-md-4 mx-3 my-4" >{languages[locale]['professionalism']}</p>
        <p className="display-4 font-Xlarge mx-md-4 mx-3 my-4" >{languages[locale]['diversity']}</p> */}
      </div>
    </section>
  );
};


const mapStateToProps = (state) => ({
  locale: state.layout.locale
})


export default connect(mapStateToProps, {})(ValuesSection);