import { GET_CATEGORIES, GET_PROJECTS_BY_CATEGORY } from '../actions/types'

const initial_state = {
    categories: [],
    projects: []
}

export default function (state = initial_state, action) {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case GET_PROJECTS_BY_CATEGORY:
            return {
                ...state,
                projects: action.payload
            }
        default:
            return state
    }
}