import React, { Component } from 'react';
import { WorkCard, PaginationList } from '../components'
import { languages } from '../../utils/languages'
import { connect } from 'react-redux'
import { getCategoriesAction } from '../../redux/actions/categoriesActions'
import { getProjectsAction } from '../../redux/actions/projectsAction'
import { LoadingOutlined } from '@ant-design/icons'
class WorksPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: undefined,
            last_page: 1,
            current_page: 1,

            is_loading_categories: false,
            is_loading_projects: false,
        }
    }
    componentDidMount() {
        this.getCategories()
    }
    getCategories = () => {
        const { getCategories } = this.props
        this.setState({ is_loading_categories: true })
        getCategories().then((res) => {
            this.setState({ is_loading_categories: false })
            this.getProjects(1)
            console.log('Response', res)
        }).catch((err) => {
            this.setState({ is_loading_categories: false })
            console.log('Error', err)
        })
    }
    getProjects = (page, id) => {
        const { getProjects } = this.props
        this.setState({ current_page: page, is_loading_projects: true })
        getProjects(page, id).then((res) => {
            this.setState({
                current_page: res.data.meta.current_page,
                last_page: res.data.meta.last_page,

                is_loading_projects: false
            }, () => {
                window.scrollTo(0, 0)
            })
            console.log('Response', res)
        }).catch((err) => {
            this.setState({ is_loading_projects: false })
            console.log('Error', err)
        })
    }
    handleChangeIndex = (index) => {
        this.setState({ activeIndex: index }, () => {
            this.getProjects(1, index)
        })
    }
    pageChange = (page) => {
        this.setState({ current_page: page }, () => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 100);
            this.getProjects(page, this.state.activeIndex)
        })
    }
    render() {
        const { locale, categories, projects } = this.props
        return (
            <React.Fragment>
                <div className='pb-md-5 pb-4' style={{ paddingTop: '9rem' }}>
                    <div className='works-page-wrapper'>
                        <div className='row mx-0'>
                            <div className='col-md-9 col-12 mx-auto'>
                                <div className='seaction-header mb-3'>
                                    <h3 className='font-Xlarge weight-600 text-theme-color-900'>
                                        {languages[locale]['our_work']}
                                    </h3>
                                </div>
                                {
                                    this.state.is_loading_categories ?
                                        <div className='py-3 d-flex justify-content-center w-100'>
                                            <span className='font-large text-theme-color'>
                                                <LoadingOutlined />
                                            </span>
                                        </div>
                                        :
                                        <header className="d-flex justify-content-center mb-4 flex-wrap" >
                                            <button

                                                className={`mx-3 my-2 font-Lsmall weight-500 btn btn-lg btn-${
                                                    this.state.activeIndex === undefined ? "primary" : "light"
                                                    }`}
                                                data-index="0"
                                                onClick={() => { this.handleChangeIndex(undefined) }}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                {languages[locale]['all']}
                                            </button>

                                            {
                                                categories.map((category, index) => (
                                                    <button key={category.id}

                                                        className={`mx-3 my-2 font-Lsmall weight-500 btn btn-lg btn-${
                                                            this.state.activeIndex === category.id ? "primary" : "light"
                                                            }`}
                                                        data-index={category.id}
                                                        onClick={() => { this.handleChangeIndex(category.id) }}
                                                        style={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        {category.name}
                                                    </button>
                                                ))
                                            }

                                        </header>
                                }
                                <div className='works-wrapper py-3'>
                                    <div className='row mx-0'>
                                        {
                                            this.state.is_loading_projects || this.state.is_loading_categories ?
                                                <div className='col-12 py-5 d-flex justify-content-center w-100'>
                                                    <span className='font-large text-theme-color'>
                                                        <LoadingOutlined />
                                                    </span>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    {
                                                        projects.length === 0 ?
                                                            <div className='col-12 py-5 d-flex justify-content-center w-100'>
                                                                <span className='font-medium text-theme-color'>
                                                                    No Projects Found
                                                                </span>
                                                            </div>
                                                            :
                                                            projects.map((project) => (
                                                                <div className="col-lg-4 col-sm-6 col-12 p-2 px-sm-2 px-4">
                                                                    <WorkCard title="اعمال الجبس" img="/images/work1.jpg" project={project} />
                                                                </div>
                                                            ))
                                                    }
                                                </React.Fragment>
                                        }


                                    </div>
                                </div>

                                <div className='pagination-list-wrapper d-flex justify-content-end mt-4'>
                                    <div className='white-card shadow-card'>
                                        <PaginationList total={this.state.last_page} current_page={this.state.current_page} onChange={this.pageChange} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => ({
    locale: state.layout.locale,
    categories: state.categories.categories,
    projects: state.projects.projects
})


export default connect(mapStateToProps, { getCategories: getCategoriesAction, getProjects: getProjectsAction })(WorksPage);
