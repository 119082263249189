import React, { FC } from "react";

interface Props {
  img: string;
  title: string;
  text: string;
}
const Feature: FC<Props> = (props) => {
  return (
    <div className="feature">
      <img src={props.img} alt={props.title} className="feature-img" />
      <h3 className="feature-title font-large">{props.title}</h3>
      <p className="small font-Lsmall">{props.text}</p>
    </div>
  );
};

export default Feature;
