import React, { Component } from 'react';
import { WorksCarousel, ProductGallery } from '../components';
import { languages } from '../../utils/languages'
import { connect } from 'react-redux'
import { getProjectDetailsAction } from '../../redux/actions/projectsAction'
import { getProjectsByCategoryAction } from '../../redux/actions/categoriesActions'
import { withRouter } from 'react-router-dom'
import SVGIcon from "../components/svgIcon";
import { LoadingOutlined } from '@ant-design/icons'
import MetaTags from 'react-meta-tags';

class WorkItemPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading_project: false,
            is_loading_projects: false
        }
    }
    componentDidMount() {
        this.getProject()
    }
    componentDidUpdate(prevProp) {
        const { match } = this.props
        const { id } = match.params

        const prevMatch = prevProp.match
        const prevId = prevMatch.params.id
        if (id != prevId) {
            this.getProject()
        }
    }
    getProject = () => {
        const { match, history, locale, getProject } = this.props
        const { id } = match.params
        this.setState({ is_loading_project: true })
        getProject(id).then((res) => {
            const { project } = this.props
            this.getProjects(project.category.id)
            this.setState({ is_loading_project: false })
        }).catch((err) => {
            this.setState({ is_loading_project: false })
            if (err.response && err.response.status === 404) {
                history.push(`/${locale}/works`)
            }
        })
    }
    getProjects = (category_id) => {
        const { getProjectsByCategory } = this.props
        this.setState({ is_loading_projects: true })
        getProjectsByCategory(category_id).then((res) => {
            this.setState({ is_loading_projects: false })
            console.log('Response', res)
        }).catch((err) => {
            this.setState({ is_loading_projects: false })
            console.log('Error', err)
        })
    }
    sendWhatsapp = () => {
        const { locale, settings } = this.props
        const { whatsapp } = settings
        const greeting = locale == 'ar' ? 'السلام عليكم، أرغب بهذا العمل' : 'Peace be upon you, I would like this work'
        const messageContent = `${window.location.href}%0A${greeting}`

        const message = (messageContent).split(' ').join('%20')

        const whatsappUrl = whatsapp.substring(0, whatsapp.indexOf('text=') + 5)
        window.open((whatsappUrl + message), '_blank')
    }
    render() {
        const { locale, project, projects } = this.props
        return (
            <React.Fragment>
                {
                    Object.keys(project).length === 0 ? '' :
                        <MetaTags>
                            <meta name="description" content={project.description} />
                            <meta property="og:title" content={project.name} />
                            <meta property="og:image" content={project.image} />
                        </MetaTags>
                }
                <div className='work-item-page pb-5 ' style={{ paddingTop: '8rem' }}>
                    <div className='row mx-0 py-5'>
                        {
                            this.state.is_loading_project ?
                                <div className='col-12  text-center d-flex justify-content-center'>
                                    <span className='font-large text-theme-color'>
                                        <LoadingOutlined />
                                    </span>
                                </div>
                                :
                                Object.keys(project).length === 0 ?
                                    <div className='col-12  text-center d-flex justify-content-center'>
                                        <span className='font-medium text-theme-color'>
                                            No Project Data
                                    </span>
                                    </div>
                                    :
                                    <div className='col-md-9 col-12 mx-auto'>
                                        <MetaTags>
                                            <meta name="description" content={project.description} />
                                            <meta property="og:title" content={project.name} />
                                            <meta property="og:image" content={project.image} />
                                        </MetaTags>
                                        <div className='row mx-0'>
                                            <div className='col-lg-6 col-12' data-aos="fade-up" data-aos-once={true} >
                                                <ProductGallery sliders={[project.image, ...project.sliders.map(slider => slider.image)]} />
                                            </div>
                                            <div className='col-lg-6 col-12 pt-lg-0 pt-2' data-aos="fade-left" data-aos-once={true}>
                                                <div className=' d-flex flex-column justify-content-between' style={{ height: '100%' }}>
                                                    <div className='description-wrapper'>
                                                        <div className='section-header'>
                                                            <h3 className='font-large weight-500 text-theme-color'>
                                                                {project.name}
                                                            </h3>
                                                        </div>
                                                        <p className='font-Lsmall weight-500 text-gray-500 mt-3'
                                                            dangerouslySetInnerHTML={{ __html: project.description }}
                                                        />
                                                    </div>
                                                    <div className='social-icons px-3 py-4'>
                                                        <button className='social-btn' onClick={this.sendWhatsapp}>
                                                            <SVGIcon id="#simple-whatsapp" title="Whats App" />
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='other-work py-4' >
                                            <div className='section-header'>
                                                <h3 className='font-Lmedium weight-600 text-theme-color'>
                                                    {languages[locale]['other_works']}
                                                </h3>
                                            </div>
                                            {
                                                this.state.is_loading_projects ?
                                                    <div className='col-12  text-center d-flex justify-content-center'>
                                                        <span className='font-large text-theme-color'>
                                                            <LoadingOutlined />
                                                        </span>
                                                    </div>
                                                    :
                                                    <WorksCarousel projects={projects} />
                                            }

                                        </div>
                                    </div>
                        }

                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    locale: state.layout.locale,
    project: state.projects.project,
    projects: state.categories.projects,
    settings: state.settings.settings
})
const WorkItemPageWrapper = connect(mapStateToProps, { getProject: getProjectDetailsAction, getProjectsByCategory: getProjectsByCategoryAction })(WorkItemPage);


export default withRouter(props => <WorkItemPageWrapper {...props} />) 