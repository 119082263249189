export const languages = {
    en: {
        //nav
        home: 'Home',
        our_work: 'Our Work',
        our_value: 'Our value',
        about: 'About',
        contact_us: 'Contact US',

        //footer
        companyAddress: 'Saudi Arabia - Riyadh - Al-Ward',
        copyRights: 'Al Doman Furniture & Decor Exhibition, Saudi Arabia © 2020',
        designedBy: 'Designed by Wide Technology company',

        //values
        quality: 'Quality',
        honesty: 'Honesty',
        customers_satisfaction: 'Customers Satisfaction',
        professionalism: 'Professionalism',
        diversity: 'Diversity',

        //about us
        aboutUsTitle: 'Domain Furniture and Decor Gallery',
        free_shipping: 'Free Shipping',
        high_efficiency: 'High Efficiency',
        secured_payment: 'Secured Payment',

        more: 'More',
        other_works: 'Other Works',
        all: 'All',

        images: 'Images',
    },
    ar: {
        //nav
        home: 'الرئيسية',
        our_work: 'أعمالنا',
        our_value: 'قيمنا',
        about: 'عن الشركة',
        contact_us: 'اتصل بنا',

        //footer
        companyAddress: 'المملكة العربية السعودية - الرياض- الورود',
        copyRights: 'معرض الدومان للأثاث والديكور ، المملكة العربية السعودية © 2020',
        designedBy: 'تصميم و برمجة شركة التقنية الموسعة',

        //values
        quality: 'الجودة',
        honesty: 'الأمانة',
        customers_satisfaction: 'رضا العملاء',
        professionalism: 'الاحترافية',
        diversity: 'التنوع',

        //about us
        aboutUsTitle: 'معرض الدومان للأثاث والديكور',
        free_shipping: 'الشحن مجانا',
        high_efficiency: 'كفاءة عالية',
        secured_payment: 'الدفع المؤمن',

        more: 'المزيد',
        other_works: 'أعمال اخرى',
        all: 'كل',

        images: 'صور',
    }
}