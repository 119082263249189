//layout
export const WINDOW_RESIZE = 'WINDOW_RESIZE'
export const CHANGE_LANG = 'CHANGE_LANG'

//categories
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_PROJECTS_BY_CATEGORY = 'GET_PROJECTS_BY_CATEGORY'

//principles
export const GET_PRINCIPLES = 'GET_PRINCIPLES'

//sliders
export const GET_SLIDERS = 'GET_SLIDERS'


//projects
export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS'

// settings
export const GET_SETTINGS = 'GET_SETTINGS'

//main images
export const GET_MAIN_IMAGES = 'GET_MAIN_IMAGES'
