export { default as Header } from "./header";
export { default as Carousel } from "./carousel";
export { default as WorkCard } from "./workCard";
export { default as SVGIcon } from "./svgIcon";
export { default as Gallery } from "./gallery";
export { default as Footer } from "./footer";
export { default as Feature } from "./feature";
export { default as ValuesSection } from "./valuesSection";
export { default as PaginationList } from "./pagination-list";
export { default as WorksCarousel } from "./works-carousel";
export { default as ProductGallery } from "./product-gallery";
export { default as SliderModal } from "./slider-modal";
export { default as ImagesCard } from "./Image-card";


