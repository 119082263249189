import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel'
import { WorkCard } from '../components'
class WorksCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { projects } = this.props
        return (
            <React.Fragment>
                <OwlCarousel
                    className="works-carousel"
                    loop={false}
                    rewind={true}
                    margin={10}
                    nav={false}
                    dots={true}
                    items={3}
                    responsive={{
                        // breakpoint from 0 up
                        0: {
                            items: 1

                        },
                        // breakpoint from 480 up
                        480: {
                            items: 2

                        },
                        // breakpoint from 768 up
                        1000: {
                            items: 3

                        }
                    }}
                >
                    {
                        projects.map((project, index) => (
                            <div className="slide" key={index}>
                                <WorkCard title="اعمال الجبس" img="/images/work1.jpg" project={project} />
                            </div>
                        ))

                    }
                </OwlCarousel>
            </React.Fragment>
        );
    }
}

export default WorksCarousel;