import { GET_SETTINGS } from './types'
import { getSettings } from '../../utils/api'
export const getSettingsAction = () => (dispatch) => {
    return getSettings().then((res) => {
        var settings = res.data.data
        dispatch({
            type: GET_SETTINGS,
            payload: settings
        })
        console.log("RESPONSE RECEIVED: ", res);
        return res
    }).catch((err) => {

        console.log("AXIOS ERROR: ", err);
        return Promise.reject(err)
    })

}