import axios from '../helperComponents/axios'

//categories
export const getCategories = () => (
    axios({
        method: 'get',
        url: `/categories`,
    }
    )
)
export const getProjectsByCategory = (id, page) => (
    axios({
        method: 'get',
        url: `/categories/${id}`,
        params: {
            page
        }
    }
    )
)

//principles
export const getPrinciples = () => (
    axios({
        method: 'get',
        url: `/principles`,
    }
    )
)

//sliders
export const getSliders = () => (
    axios({
        method: 'get',
        url: `/sliders`,
    }
    )
)

// projects
export const getProjects = (page, category) => (
    axios({
        method: 'get',
        url: `/projects`,
        params: {
            page,
            category
        }
    }
    )
)

export const getProjectDetails = (id) => (
    axios({
        method: 'get',
        url: `/projects/${id}`,
    }
    )
)

// settings
export const getSettings = () => (
    axios({
        method: 'get',
        url: `/settings`,
    }
    )
)
// images
export const getImages = (page, category) => (
    axios({
        method: 'get',
        url: `/category-images`,
        params: {
            page,
            category
        }
    }
    )
)