import { GET_PRINCIPLES } from '../actions/types'

const initial_state = {
    principles: [],
}

export default function (state = initial_state, action) {
    switch (action.type) {
        case GET_PRINCIPLES:
            return {
                ...state,
                principles: action.payload
            }

        default:
            return state
    }
}