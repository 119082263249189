import React, { FC, useEffect, useState } from "react";
import { ValuesSection, Feature } from "../components";
import { connect } from 'react-redux'
import { languages } from '../../utils/languages'
import { getPrinciplesAction } from '../../redux/actions/principlesActions'
import { LoadingOutlined } from "@ant-design/icons";

const AboutPage = (props) => {

  const [is_loading_Principles, set_loading_Principles] = useState(false)
  useEffect(() => {
    const { getPrinciples } = props



    set_loading_Principles(true)
    getPrinciples().then((res) => {
      set_loading_Principles(false)
    }).catch((err) => {
      set_loading_Principles(false)
    })

  }, []);
  const { locale, principles, settings } = props
  return (
    <div className="about" style={{ paddingTop: '6rem' }}>
      <section className="section about-us d-lg-flex align-items-start container">
        <div className='row w-100'>
          <div className='col-lg-5 col-12'>
            <img
              src={settings.about_us_image}
              alt="Aldoomain Logo"
              className="about-img w-100"
              data-aos="fade-up" data-aos-once={true}
            />
          </div>
          <div className='col-lg-7 col-12'>
            <div data-aos="fade-left" data-aos-once={true}>
              <h2 className="about-title font-large">{languages[locale]['aboutUsTitle']}</h2>
              <p dangerouslySetInnerHTML={{ __html: settings.about_us_description }}>
              </p>
            </div>
          </div>
        </div>


      </section>
      {/* <section className="section features">
        <div className="container">
          <div className="row">
            <div className="col-md-4" data-aos="fade-up" data-aos-delay='10'>
              <Feature
                img="/images/car.svg"
                title={languages[locale]['free_shipping']}
                text="و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر"
              />
            </div>
            <div className="col-md-4" data-aos="fade-up" data-aos-delay='50'>
              <Feature
                img="/images/shield.svg"
                title={languages[locale]['high_efficiency']}
                text="و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر"
              />
            </div>
            <div className="col-md-4" data-aos="fade-up" data-aos-delay='100'>
              <Feature
                img="/images/card.svg"
                title={languages[locale]['secured_payment']}
                text="و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر"
              />
            </div>
          </div>
        </div>
      </section> */}
      {
        is_loading_Principles ?
          <div className='py-4 d-flex justify-content-center'>
            <span className='font-large text-center'>
              <LoadingOutlined />
            </span>
          </div>
          :
          <ValuesSection principles={principles} />
      }

    </div>
  );
};


const mapStateToProps = (state) => ({
  locale: state.layout.locale,
  principles: state.principles.principles,
  settings: state.settings.settings
})
export default connect(mapStateToProps, { getPrinciples: getPrinciplesAction })(AboutPage);