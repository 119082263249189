import { WINDOW_RESIZE, CHANGE_LANG } from './types'
export const resizeWindow = (window_size) => (dispatch) => {
    dispatch({
        type: WINDOW_RESIZE,
        payload: window_size,
    })

}

export const changeLocale = (locale) => (dispatch) => {
    localStorage.setItem('locale', locale)
    dispatch({
        type: CHANGE_LANG,
        payload: locale,
    })

}