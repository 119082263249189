import React, { Component } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import { WorkCard, PaginationList } from '../components'
import { languages } from '../../utils/languages'
import { connect } from 'react-redux'
import { getCategoriesAction } from '../../redux/actions/categoriesActions'
import { getImagesAction } from '../../redux/actions/mainImagesActions'
import { LoadingOutlined } from '@ant-design/icons'
import { SliderModal } from '../components'

import { Link } from 'react-router-dom'
class GallerySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      viewerIsOpen: false,

      activeIndex: undefined,
      last_page: 1,
      current_page: 1,

      images: [],
      is_loading_categories: false,
      is_loading_projects: false,


    }
  }
  componentDidMount() {
    this.getCategories()
  }
  getCategories = () => {
    const { getCategories } = this.props
    this.setState({ is_loading_categories: true })
    getCategories().then((res) => {
      this.setState({ is_loading_categories: false })
      this.getImages(1)
      console.log('Response', res)
    }).catch((err) => {
      this.setState({ is_loading_categories: false })
      console.log('Error', err)
    })
  }
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  getRandomImages = (images) => {
    const { window_width } = this.props
    if (images.length != 0) {
      var imagesArray = []
      var range = window_width > 991 ? 8 : 2

      for (var i = 0; i < range; i++) {
        var randomImage = images[this.getRandomInt(0, images.length - 1)]
        if (imagesArray.length >= images.length) {
          imagesArray.push(randomImage)
        } else {
          if (imagesArray.includes(randomImage)) {
            i--
            continue
          } else {
            imagesArray.push(randomImage)
          }
        }

      }
      imagesArray = imagesArray.map((image) => ({
        src: image.image,
        width: 1.5,
        height: 1,
      }))
      this.setState({ images: imagesArray })
    } else {
      this.setState({ images: [] })
    }
  }
  getImages = (page, id) => {
    const { getImages } = this.props
    this.setState({ current_page: page, is_loading_projects: true })
    getImages(page, id).then((res) => {
      const { images } = this.props
      this.getRandomImages(images)


      this.setState({
        current_page: res.data.meta.current_page,
        last_page: res.data.meta.last_page,

        is_loading_projects: false
      })
      console.log('Response', res)
    }).catch((err) => {
      this.setState({ is_loading_projects: false })
      console.log('Error', err)
    })
  }
  handleChangeIndex = (index) => {
    this.setState({ activeIndex: index }, () => {
      this.getImages(1, index)
    })
  }

  openLightbox = (event, { photo, index }) => {
    console.log(index)
    this.setState({ currentImage: index, viewerIsOpen: true })

  }

  closeLightbox = () => {
    this.setState({ currentImage: 0, viewerIsOpen: false })

  };
  render() {
    const { locale, categories, images } = this.props
    return (
      <div className="gallery">
        {
          this.state.is_loading_categories ?
            <div className='py-3 d-flex justify-content-center w-100'>
              <span className='font-large text-theme-color'>
                <LoadingOutlined />
              </span>
            </div>
            :
            <header className="d-flex justify-content-center mb-4 flex-wrap" >
              {/* <button

                className={`mx-3 my-2 font-Lsmall weight-500 btn btn-lg btn-${
                  this.state.activeIndex === undefined ? "primary" : "light"
                  }`}
                data-index="0"
                onClick={() => { this.handleChangeIndex(undefined) }}
                style={{ whiteSpace: 'nowrap' }}
              >
                {languages[locale]['all']}
              </button> */}

              {
                categories.map((category, index) => (
                  <button

                    className={`mx-3 my-2 font-Lsmall weight-500 btn btn-lg btn-${
                      this.state.activeIndex === category.id ? "primary" : "light"
                      }`}
                    data-index={category.id}
                    onClick={() => { this.handleChangeIndex(category.id) }}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {category.name}
                  </button>
                ))
              }

            </header>
        }
        <div style={{ minHeight: '25rem' }}>

          {

            this.state.is_loading_projects ?
              <div style={{ padding: '9rem 0' }}>
                <div className='py-5 d-flex justify-content-center'>
                  <span className='font-large weight-600 text-theme-color'>
                    <LoadingOutlined />
                  </span>
                </div>
              </div>
              :
              this.state.images.length == 0 ?
                <div className='py-3 d-flex justify-content-center'>
                  <span className='font-medium weight-600 text-theme-color'>
                    No Images To Be Shown
              </span>
                </div>
                :
                <React.Fragment>

                  {/* <SliderModal sliders={images} /> */}
                  <div className='px-md-5 px-4 py-3 d-flex justify-content-end'>
                    <Link className='font-medium link-theme-color weight-600' to={`/${locale}/images${this.state.activeIndex ? `/${this.state.activeIndex}` : ''}`}>
                      {languages[locale]['more']}
                    </Link>
                  </div>
                  <Gallery photos={this.state.images} onClick={this.openLightbox} />
                  <ModalGateway style={{ direction: 'ltr' }}>
                    {this.state.viewerIsOpen ? (
                      <Modal onClose={this.closeLightbox}>
                        <Carousel
                          currentIndex={this.state.currentImage}
                          views={this.state.images.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.layout.locale,
  categories: state.categories.categories,
  images: state.main_images.images,
  window_width: state.layout.window_width
})


export default connect(mapStateToProps, { getCategories: getCategoriesAction, getImages: getImagesAction })(GallerySection);
