import React, { Component } from 'react';

import SVGIcon from "./svgIcon";
import { connect } from 'react-redux'
import { Modal } from 'antd'
class ImagesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_modal_visible: false
    }
  }
  sendWhatsapp = (e) => {
    e.stopPropagation()
    const { locale, image, settings } = this.props
    const { whatsapp } = settings
    const greeting = locale == 'ar' ? 'السلام عليكم، أرغب بهذا العمل' : 'Peace be upon you, I would like this work'
    const messageContent = `${encodeURIComponent(image.image)}%0A${greeting}`

    const message = (messageContent).split(' ').join('%20')

    const whatsappUrl = whatsapp.substring(0, whatsapp.indexOf('text=') + 5)
    window.open((whatsappUrl + message), '_blank')
  }
  render() {
    const { locale, image } = this.props
    return (
      <React.Fragment>
        <span className="work-card cursor-pointer" data-aos="fade-up" data-aos-duration={800} data-aos-once={true} onClick={() => { this.setState({ image_modal_visible: true }) }}>
          <img className="card-img" src={image.image} alt="Card" />
          <div className="card-img-overlay">



            <h5 className=" font-large text-white"></h5>
            <div className="card-social">
              <button onClick={this.sendWhatsapp}>
                <SVGIcon id="#simple-whatsapp" title="Whats App" />
              </button>
              {/* <button>
              <SVGIcon id="#simple-whatsapp" title="Whats App" />
            </button>
            <button>
              <SVGIcon id="#simple-whatsapp" title="Whats App" />
            </button> */}
            </div>
          </div>
        </span>
        <Modal
          title={false}
          footer={false}
          closable={false}
          centered={true}
          visible={this.state.image_modal_visible}
          onCancel={() => { this.setState({ image_modal_visible: false }) }}
          className='image-modal'
        >
          <img className='image w-100' src={image.image} />
        </Modal>
      </React.Fragment>
    );
  }
}



const mapStateToProps = (state) => ({
  locale: state.layout.locale,
  settings: state.settings.settings
})
export default connect(mapStateToProps, {})(ImagesCard);
