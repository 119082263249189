import { GET_SETTINGS } from '../actions/types'

const initial_state = {
    settings: {},
}
export default function (state = initial_state, action) {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                settings: action.payload
            }

        default:
            return state
    }
}