import { GET_SLIDERS } from '../actions/types'

const initial_state = {
    sliders: [],
}
export default function (state = initial_state, action) {
    switch (action.type) {
        case GET_SLIDERS:
            return {
                ...state,
                sliders: action.payload
            }

        default:
            return state
    }
}