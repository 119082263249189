import Axios from "axios";
import https from 'https';
const httpsAgent = new https.Agent({
    rejectUnauthorized: false, // (NOTE: this will disable client verification)

})
const api = Axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
    headers: {
        "x-api-key": 'ZCCbKOUHrcU2HxmZgqft70ba4iy5lhJj6BLL5xcfOtRLZH5iQKzHoPSb44mBgxfT',
        'Content-Type': ' application/json',
        'Accept': 'application/json',
    },
    httpsAgent
});
export default api; 