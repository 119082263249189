import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import { connect } from 'react-redux'
class ProductGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    renderLeftNav = (onClick, disabled) => (
        <React.Fragment></React.Fragment>
    )
    renderRightNav = (onClick, disabled) => (
        <React.Fragment></React.Fragment>
    )
    render() {
        const { sliders } = this.props
        const images = sliders.map((slider) => ({ original: slider, thumbnail: slider }))

        const { locale } = this.props
        return (
            <React.Fragment>
                <ImageGallery items={images} isRTL={locale === 'ar'} autoPlay={false} showPlayButton={false} renderLeftNav={this.renderLeftNav} renderRightNav={this.renderRightNav} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.layout.locale
})
export default connect(mapStateToProps, {})(ProductGallery);