import { GET_CATEGORIES, GET_PROJECTS_BY_CATEGORY } from './types'
import { getCategories, getProjectsByCategory } from '../../utils/api'

export const getCategoriesAction = () => (dispatch) => {
    return getCategories().then((res) => {
        var categories = res.data.data.map((category) => { return { id: category.id, name: category.name } })
        dispatch({
            type: GET_CATEGORIES,
            payload: categories
        })
        console.log("RESPONSE RECEIVED: ", res);
        return res
    }).catch((err) => {

        console.log("AXIOS ERROR: ", err);
        return Promise.reject(err)
    })

}
export const getProjectsByCategoryAction = (id, page) => (dispatch) => {
    return getProjectsByCategory(id, page).then((res) => {
        var projects = res.data.data.projects
        dispatch({
            type: GET_PROJECTS_BY_CATEGORY,
            payload: projects
        })
        console.log("RESPONSE RECEIVED: ", res);
        return res
    }).catch((err) => {
        console.log("AXIOS ERROR: ", err);
        return Promise.reject(err)
    })

}