import React, { FC } from "react";
import { Link } from "react-router-dom";
import SVGIcon from "./svgIcon";
import { connect } from 'react-redux'

const WorkCard = (props) => {
  const { locale, project } = props
  return (
    <Link className="work-card" to={`/${locale}/works/${project.id}`} data-aos="fade-up" data-aos-duration={800} data-aos-once={true}>
      <img className="card-img" src={project.image} alt="Card" />
      <div className="card-img-overlay">

        <h5 className=" font-large text-white">{project.name}</h5>

        {/* <div className="card-social">
          <button>
            <SVGIcon id="#simple-whatsapp" title="Whats App" />
          </button>
          <button>
            <SVGIcon id="#simple-whatsapp" title="Whats App" />
          </button>
          <button>
            <SVGIcon id="#simple-whatsapp" title="Whats App" />
          </button>
        </div> */}
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  locale: state.layout.locale
})
export default connect(mapStateToProps, {})(WorkCard);
