import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, Link, useLocation, withRouter } from "react-router-dom";
import { HashLink } from 'react-router-hash-link'
import SVGIcon from "./svgIcon";
import { connect } from 'react-redux'
import { nthIndex } from '../../helperFunctions/functions'
import { languages } from '../../utils/languages'

const Header = (props) => {
  const { pathname } = useLocation();
  const { locale } = props



  var [variant, set_variant] = useState(pathname === `/${locale}` || pathname === `/${locale}/` ? "dark" : "light")
  const [windowY, set_scroll] = useState(window.scrollY)
  useEffect(() => {
    if (pathname === `/${locale}` || pathname === `/${locale}/`) {

      window.addEventListener('scroll', function (e) {
        set_scroll(window.scrollY)
      });
      if (windowY <= 200) {
        set_variant('dark')
      } else {
        set_variant('light')
      }
    }
    else {
      set_variant('light')
    }

  }, [pathname, windowY]);
  return (
    <header className={`header ${variant}`}>
      <Navbar expand="md" variant={variant}>
        <Link to="/" className="navbar-brand">
          <img
            src={`/images/${
              variant === "light" ? "logo.png" : "logo-white.png"
              }`}
            alt="Aldoomain Logo"
          />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <nav className="navbar-nav ml-auto">
            <NavLink className="nav-link" to={`/${locale}`} exact={true}>
              {
                languages[locale]['home']
              }

            </NavLink>
            <HashLink className="nav-link" to={`/${locale}#works`}>
              {
                languages[locale]['our_work']
              }
            </HashLink>
            <HashLink className="nav-link" to={`/${locale}#values`}>
              {
                languages[locale]['our_value']
              }
            </HashLink>
            <NavLink className="nav-link" to={`/${locale}/about-us`}>
              {
                languages[locale]['about']
              }
            </NavLink>
            <HashLink
              className={`btn btn-${
                variant === "dark" ? "secondary" : "primary"
                } mr-md-3`}
              to={`/${locale}#contact-us`}
            >
              {
                languages[locale]['contact_us']
              }
            </HashLink>


            {locale == 'en' ?
              <a className='header__lang-btn' href={`/ar/${props.location.pathname.substring(nthIndex(props.location.pathname, '/', 2) + 1, props.location.pathname.length)}${props.location.search}`}>
                عربى&nbsp;
              <SVGIcon id="#globe" />
              </a>
              :
              <a className='header__lang-btn' href={`/en/${props.location.pathname.substring(nthIndex(props.location.pathname, '/', 2) + 1, props.location.pathname.length)}${props.location.search}`}>
                EN&nbsp;
              <SVGIcon id="#globe" />
              </a>
            }

          </nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

const mapStateToProps = (state) => ({
  locale: state.layout.locale
})


const NavbarCompenent = connect(mapStateToProps, {})(Header);

export default withRouter(props => <NavbarCompenent {...props} />);